<template>
  <v-container>
    <v-row v-if="isLoggedIn">
      <v-col>
           <v-alert
            v-if="error"
            type="error"
           >{{error}}</v-alert>
        <v-dialog v-model="imageGal" max-width="800px">
          <ImageGallery @imageClick="imageClicked" collection="images" :model="imagesModel"></ImageGallery>
        </v-dialog>
         <UnlayerEditor
             :appearance="appearance"
             :min-height="minHeight"
             :locale="locale"
             :tools="tools"
             ref="unlayerEditor"
             :options="options"
             :mode="web?'web':'email'"
             :key="templateID"
             v-on:load="editorLoaded"
             :uploads="editorFiles"
         />
      </v-col>
    </v-row>
    <v-alert
     v-if="!isLoggedIn"
     type="error"
    >Sie sind nicht angemeldet</v-alert>
  </v-container>
</template>
<style>
.unlayer-editor iframe{
  min-height:1000px !important;
}
</style>
<script>
import gql from 'graphql-tag';
import UnlayerEditor from '@/components/wysiwyg/UnlayerEditor';
import ImageGallery from "@/components/views/ImageGallery";

/*const CREATE_MAILTEMPLATE = gql`
mutation createMailTemplate ($design: JSON! $name: String!){
  createMailTemplate(input:{data:{design: $design name: $name}}){
    mailTemplate{id}
  }
}
`*/

const UPDATE_MAILTEMPLATE = gql`
mutation updateMailTemplate ($design: JSON! $templateID: ID!){
  updateMailTemplate(input:{
      data:{
        design: $design
        }
      where: {id:$templateID}
      }){
    mailTemplate{id}
  }
}
`

// noinspection GraphQLUnresolvedReference
const LOAD_DEFAULT_TEMPLATE = gql`
query mailTemplates{
  mailTemplates(where:{name:"default"}){
    design
  }
}
`
const LOAD_TEMPLATE = gql`
# noinspection GraphQLUnresolvedReference
query mailTemplates($templateID: ID!){
  mailTemplates(where:{id:$templateID}){
    design
  }
}
`
/*
mailTemplates(
sort: String
limit: Int
start: Int
where: JSON
publicationState: PublicationState
): [MailTemplate]
*/
export default {
  name: 'TemplateEditor',
  components: {
    UnlayerEditor,
    ImageGallery
  },
  props: {
    templateID:{
      type: String,
      required: false
    },
    design:{
      type: Object,
      required: false
    },
    web: {
      type: Boolean,
      required: false
    },
    templateName:{
      type: String,
      required: false
    },
    fields: {
      type: Object,
      required: false
    },
    delims: {
      type: Array,
      required: false
    },
    files: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      emailDesign:{},
      imagesModel:{
        id: false,
        uploads:{
          type:"media",
          model:{
            id: false,
            caption: "Beschreibung",
            url: "Vorschau",
            formats: false
          }
        }
      },
      name: this.$props.templateName,
      error:null,
      minHeight: "1000px",
      locale: "de",
      tools: {
        // disable image tool
        // image: {
        //   enabled: false
        // }
      },
      displayMode: this.$props.web ? 'web' : 'email',
      appearance: {
        theme: 'dark',
        panels: {
          tools: {
            dock: 'right'
          }
        }
      },
      imageGal: false,
      imageSelected: null
    }
  },
  computed: {
    editorFiles() {
      return this.$props.files
          ?
          this.$props.files.map(x => {
            return {
              label: x.name,
              value: x.url
            }
          })
          : []
    },
    delimiter() {
      return this.$props.delims || ["<%=", "%>"]
    },
    isLoggedIn() {
      return typeof localStorage.getItem('token') === "string" && localStorage.getItem('token') !== '';
    },
    options: () => {
      return {
        mergeTagsConfig: {
          sort: false
        },
        translations: {
          'de': {
            "buttons.upload_image": "Bildergallerie öffnen",
            "labels.merge_tags": "Platzhalter",
          }
        },
        fonts: {
          showDefaultFonts: false,
          // customFonts: [
          //   {
          //     label: "Comic Sans",
          //     value: "'Comic Sans MS', cursive, sans-serif"
          //   },
          //   {
          //     label: "Lobster Two",
          //     value: "'Lobster Two',cursive",
          //     url: "https://fonts.googleapis.com/css?family=Lobster+Two:400,700"
          //   }
          // ]
        }
      }
    }
  },
  methods: {
    imageClicked(url) {
      if (typeof this.imageSelected === 'function') {
        this.imageSelected({url: url});
      }
      this.imageGal = false;
    },
    editorLoaded() {
      let that = this;
      if(that.$props.design !== undefined){
        that.$refs.unlayerEditor.editor.loadDesign(that.$props.design);
      }else{
        // Pass the template JSON here
        // this.$refs.unlayerEditor.editor.loadDesign({});
        //get default
        let aql;
        if(that.$props.templateID === undefined){
          aql = {query: LOAD_DEFAULT_TEMPLATE,fetchPolicy: 'no-cache'};
        }else{
          aql = {
            query: LOAD_TEMPLATE,
            variables: { templateID: this.$props.templateID},
            fetchPolicy: 'no-cache'
          };
        }
        that.$apollo.query(aql).then((data) => {
          //console.log(data.data.mailTemplates[data.data.mailTemplates.length-1]);
          //console.log(data.data.mailTemplates[data.data.mailTemplates.length-1].design);
          if(data.data.mailTemplates[data.data.mailTemplates.length-1].design.schemaVersion !== undefined){
            //console.log("load design");
            that.$refs.unlayerEditor.editor.loadDesign(data.data.mailTemplates[data.data.mailTemplates.length - 1].design);
          }

        }).catch((error) => {
          // Error
          console.error(error);

          that.error = "Design konnte nicht geladen werden. Mehr Details in der Fehlerkonsole.";


        });
      }


      this.$refs.unlayerEditor.editor.registerCallback('selectImage', function (data, done) {
        that.imageGal = true;
        that.imageSelected = done;
        //done({ url: '#' });
      });

      if(this.$props.fields){
        let mergeTags = {};
        const fields = this.$props.fields;
        for(let field in fields){
          if(fields[field] !== false){
            mergeTags[field] = {};
            if(fields[field].mergeTags !== undefined){
              mergeTags[field].name = fields[field].label || fields[field].name || fields[field];
              const subFields = fields[field].mergeTags;
              mergeTags[field].mergeTags = {};
              for(let f in subFields){
                if(subFields[f] !== false) {
                  mergeTags[field].mergeTags[f] = {
                    name: subFields[f].label || subFields[f].name || subFields[f],
                    value: subFields[f].name || this.delimiter[0] + f + this.delimiter[1]
                  };
                }
              }

              //order fields
              mergeTags[field].mergeTags = Object.keys(mergeTags[field].mergeTags).sort().reduce(
                  (obj, key) => {
                    obj[key] = mergeTags[field].mergeTags[key];
                    return obj;
                  },
                  {}
              );

              //mergeTags[field] = fields[field].mergeTags;
            }else{
              const varName = typeof fields[field].unlayer === 'string' ? fields[field].unlayer : field;
              mergeTags[field] = {
                name: fields[field].label || fields[field].name || fields[field],
                value: fields[field].name || this.delimiter[0] + varName + this.delimiter[1]
              };
            }
          }
        }
        this.$refs.unlayerEditor.editor.setMergeTags(mergeTags);
      }
    },
    saveDesign(cb) {
      this.$refs.unlayerEditor.editor.saveDesign(
          (design) => {
            //console.log('saveDesign', design);
            this.$apollo.mutate({
              mutation: UPDATE_MAILTEMPLATE, variables: {
                design: design,
                templateID: this.$props.templateID
              }
            }).then(() => {
              if (typeof cb === 'function') {
                cb(design);
              }
            }).catch((error) => {
            // Error
            console.error(error);

            if(error.message.indexOf("Duplicate entry") !== -1){
              this.error = "Bitte einen anderen Namen wählen. Dieser name ist schon vergeben.";
            }else{
              this.error = "Design konnte nicht erstellt werden. Mehr Details in der Fehlerkonsole.";
            }
          });
        }
      )
    },
    export(cb){
      let design;
      let html;
      const doneF = () => {
        if(design !== undefined && html !== undefined && typeof cb === 'function'){
          cb({
            design: design,
            html: html
          });
        }
      };
      this.exportDesign(x=> {
        design = x;
        doneF();
      });
      this.exportHtml(x=> {
        html = x;
        doneF();
      });
    },
    exportDesign(cb) {
      this.$refs.unlayerEditor.editor.saveDesign(
          (design) => {
            if (typeof cb === 'function') {
              cb(design);
            }
          }
      )
    },
    exportHtml(cb) {
      this.$refs.unlayerEditor.editor.exportHtml(
          (data) => {
            if (typeof cb === 'function') {
              cb(data.html);
            }
          }
      )
    }
  }
}
</script>
